<template>
   <main>
      <slide />

      <!-- lancamentos day area start -->
      <lancamentos :array="clienteData.lancamentos" :titulo="'Lançamentos'" :classes="'lancamentos__area pt-50 pb-50 grey-bg'" />

      <!-- brand area start -->
      <section class="brand__area pt-40" v-show="clienteData.marcas != null && clienteData.marcas.length > 0">
         <div class="container custom-container">
            <div class="section__head mb-20">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Nossas marcas</h3>
               </div>
            </div>
            <div class="row align-items-center">
               <div class="col-xl-12">
                  <logoMarcas :view="'Home'" />
               </div>
            </div>
         </div>
      </section>

      <!-- blog area start -->
      <section class="blog__area pt-40 pb-40 grey-bg" v-show="clienteData.noticias != null && clienteData.noticias.length > 0">
         <div class="container">
            <div class="section__head mb-20">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Notícias</h3>
               </div>
            </div>
            <div class="grid">
               <noticia v-for="(noticia, index) in clienteData.noticias" :key="index" :noticia="noticia" :index="index" :view="'Grid'" />
            </div>
         </div>
      </section>

      <!-- about start -->
      <section class="about__area pt-40 pb-40">
         <div class="container">
            <div class="section__head mb-20">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Sobre nós</h3>
               </div>
            </div>
            <div class="row align-items-center">
               <div class="col-xl-6 px-xxl-5">
                  <div class="w-img pb-5 pb-xxl-0">
                     <img class="rounded" src="@/assets/cliente/img/banner/empresa-01.jpg" />
                  </div>
               </div>
               <div class="col-xl-6 px-xxl-5 text-justify">
                  <p v-html="sourceHome.sobreNos_descricao"></p>
                  <div class="w-100 text-center mt-40">
                     <router-link to="/historia" class="btn btn-outline-primary font-14"><span v-html="sourceHome.sobreNos_botaoVerMais"></span> <i class="fas fa-long-arrow-right font-12 ms-1"></i></router-link>
                  </div>
               </div>
               <div class="col-12 mt-40 d-none d-lg-block">
                  <div class="d-flex justify-content-center">
                     <div class="w-230 px-2">
                        <div class="card bg-theme mb-0">
                           <div class="card-body px-1 py-4 text-center text-white">
                              <h3 class="count mb-1">85000</h3><p class="mb-0">m² de área total</p>
                           </div>
                        </div>
                     </div>
                     <div class="w-230 px-2">
                        <div class="card bg-theme mb-0">
                           <div class="card-body px-1 py-4 text-center text-white">
                              <h3 class="count mb-1">45000</h3><p class="mb-0">m² de área construída</p>
                           </div>
                        </div>
                     </div>
                     <div class="w-230 px-2">
                        <div class="card bg-theme mb-0">
                           <div class="card-body px-1 py-4 text-center text-white">
                              <h3 class="count mb-1">2000</h3><p class="mb-0">equipamentos</p>
                           </div>
                        </div>
                     </div>
                     <div class="w-230 px-2">
                        <div class="card bg-theme mb-0">
                           <div class="card-body px-1 py-4 text-center text-white">
                              <h3 class="count mb-1">70000</h3><p class="mb-0">peças de reposição</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-12 mt-50 d-none d-lg-block">
                  <div class="video__content px-5">
                     <iframe :src="sourceHome.sobreNos_videoURL" title="Institucional" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
               </div>
               <div class="col-12 mt-50 d-none d-xl-block" v-if="clienteData.depoimentos != null && clienteData.depoimentos.length > 0">
                  <div class="d-flex justify-content-center">
                     <depoimento v-for="(depoimento, index) in clienteData.depoimentos" :key="index" :depoimento="depoimento" />
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- revendas start -->
      <section class="revendas__area pt-50 pb-xl-5 grey-bg">
         <div class="revendas__head pb-4 pb-xl-5">
            <div class="section__head">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Revendas</h3>
               </div>
            </div>
         </div>
         <div class="theme-bg">
            <div class="container pt-30 pb-30">
               <div class="row">
                  <div class="col-xl-6 px-xxl-5">
                     <img src="@/assets/cliente/img/banner/empresa-02.jpg" />
                  </div>
                  <div class="col-xl-6 px-xxl-5 text-white align-self-center text-justify">
                     <p class="limitador-10" v-html="sourceHome.sejaRevendedor_descricao"></p>
                     <div class="w-100 d-flex px-md-5 mt-30">
                        <hr class="col">
                        <div class="w-max-content ps-4 pt-1 weight-600 font-16">
                           <router-link to="/sejaRevendedor">Seja um revendedor <i class="fas fa-plus font-16 ms-2"></i></router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- downloads start -->
      <section class="downloads__area pt-50 pb-50">
         <div class="container">
            <div class="section__head mb-30">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Downloads</h3>
               </div>
            </div>
            <div class="row justify-content-center px-5">
               <div class="col-md-6 col-lg-4 px-xl-5 mb-4 text-center">
                  <img src="@/assets/cliente/img/icon/catalogo.png" width="128" class="d-none d-md-inline" />
                  <h1 class="mt-3 mb-2 color-theme font-20">Catálogos</h1>
                  <p v-html="sourceHome.downloads_catalogosDescricao"></p>
                  <router-link to="/downloads" class="btn btn-primary font-14">Acesse</router-link>
               </div>
               <div class="col-md-6 col-lg-4 px-xl-5 mb-4 text-center">
                  <img src="@/assets/cliente/img/icon/manual.png" width="128" class="d-none d-md-inline" />
                  <h1 class="mt-3 mb-2 color-theme font-20">Manuais</h1>
                  <p v-html="sourceHome.downloads_manuaisDescricao"></p>
                  <router-link to="/downloads" class="btn btn-primary font-14">Acesse</router-link>
               </div>
               <div class="col-lg-4 px-xl-5 mb-4 text-center">
                  <img src="@/assets/cliente/img/icon/folheto.png" width="128" class="d-none d-md-inline" />
                  <h1 class="mt-3 mb-2 color-theme font-20">Folhetos</h1>
                  <p v-html="sourceHome.downloads_folhetosDescricao"></p>
                  <router-link to="/downloads" class="btn btn-primary font-14">Acesse</router-link>
               </div>
            </div>
         </div>
      </section>

      <!-- videos start -->
      <section class="videos__area pt-50 pb-50">
         <div class="container pt-30 pb-30">
            <div class="row text-white justify-content-center justify-content-sm-start">
               <div class="w-max-content px-xl-5 d-flex align-items-center">
                  <i class="fal fa-desktop font-170 d-none d-sm-inline"></i>
                  <div class="w-290 ms-sm-5 text-center text-sm-start">
                     <h3>Vídeos</h3>
                     <p class="limitador-4" v-html="sourceHome.videos_descricao"></p>
                     <a :href="contato.youtube" target="_blank" class="btn btn-primary font-14">Acesse</a>
                  </div>
               </div>
               <div class="col d-none d-sm-block"></div>
            </div>
         </div>
      </section>

      <!-- encontre__revenda start -->
      <section class="encontre__revenda__area pt-70 pb-70">
         <div class="container">
            <div class="row justify-content-center px-sm-5">
               <div class="w-max-content color-theme py-md-3">
                  <i class="fal fa-map-marker-alt font-170 d-none d-md-block"></i>
                  <i class="fal fa-map-marker-alt font-70 mb-40 d-block d-md-none"></i>
               </div>
               <div class="col-md ms-md-5 text-center text-md-start">
                  <p class="limitador-8" v-html="sourceHome.encontreRevenda"></p>
                  <div class="w-100 d-flex px-lg-5 mt-30">
                     <hr class="col bg-theme">
                     <div class="w-max-content ps-4 pt-1 weight-600 font-16 color-theme">
                        <router-link to="/encontreRevenda">Encontre uma revenda <i class="fas fa-plus font-16 ms-2"></i></router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- contato start -->
      <section class="contato__area pt-50 pb-60 grey-bg">
         <div class="container">
            <div class="section__head mb-20">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Fale com a gente</h3>
               </div>
            </div>
            <div class="row align-items-center">
               <div class="col-xxl-6 px-xxl-5 text-justify">
                  <div class="row">
                     <div class="col-12 mb-3">
                        <p v-html="sourceHome.faleConosco_descricao"></p>
                     </div>
                     <div class="col-12 mb-2">
                        <label>Nome *</label>
                        <input type="text" class="form-control" v-model="faleConosco.nome" maxlength="200" />
                     </div>
                     <div class="col-12 mb-2">
                        <label>Telefone *</label>
                        <input type="text" class="form-control" v-model="faleConosco.telefone" maxlength="200" />
                     </div>
                     <div class="col-12 mb-2">
                        <label>Email *</label>
                        <input type="email" class="form-control" v-model="faleConosco.email" maxlength="200" />
                     </div>
                     <div class="col-12 mb-2">
                        <label>Mensagem *</label>
                        <textarea class="form-control" v-model="faleConosco.mensagem" maxlength="200"></textarea>
                     </div>
                     <div class="col-12 text-end">
                        <button class="t-y-btn t-y-btn-2" @click="enviarFaleConosco">Enviar</button>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-6 d-none d-xxl-block">
                  <div class="w-img">
                     <img class="rounded" src="@/assets/cliente/img/banner/empresa-03.jpg" />
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import slide from '@/components/Slide.vue'
import noticia from '@/components/noticias/Noticia.vue'
import depoimento from '@/components/home/Depoimento.vue'
import lancamentos from '@/components/CarouselProdutos.vue'
import logoMarcas from '@/components/CarouselLogoMarcas.vue'

export default {
	name: 'Home',
   data : function () {
      return {
         faleConosco: {'nome': '', 'telefone': '', 'email': '', 'mensagem': ''},
         observer: null
      }
   },
   computed: {
		... mapState({
			isRouteAdmin: state => state.isRouteAdmin,
			clienteData: state => state.clienteData,
			contato: state => state.source.contato,
			sourceHome: state => state.source.home,
         urlRest: state => state.urlRest
		})
	},
   components: {
      logoMarcas, lancamentos, noticia, slide, depoimento
   },
   methods: {
      enviarFaleConosco : function () {
         if (this.faleConosco.nome == null || String(this.faleConosco.nome).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Nome inválido!'
            });

            return
         } else if (this.faleConosco.telefone == null || String(this.faleConosco.telefone).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Telefone inválido!'
            });

            return
         } else if (this.faleConosco.email == null || String(this.faleConosco.email).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Email inválido!'
            });

            return
         } else if (this.faleConosco.mensagem == null || String(this.faleConosco.mensagem).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Mensagem inválida!'
            });

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'site/faleConosco',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.faleConosco

         }).then(() => {
            this.faleConosco = {'nome': '', 'telefone': '', 'email': '', 'mensagem': ''}

            Swal.fire({
               icon: 'success',
               title: 'Mensagem enviada!'
            });
         }).catch((error) => {
            if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });      
      },
      animacaoContagem : function (entries) {
         entries.forEach(entry => {
            if (entry.isIntersecting) {
               $('.count').each(function () {
                  $(this).prop('Counter', 0).animate({
                     Counter: $(this).text()
                  }, {
                     duration: 1000,
                     easing: 'swing',
                     step: function (now) {
                        $(this).text(Math.ceil(now));
                     }
                  });
               });
            }
         });
      }
   },
   mounted() {
      Vue.nextTick(function() {
         this.observer = new IntersectionObserver(this.animacaoContagem, {threshold: 1.0, delay: 500});
         this.observer.observe(document.querySelector(".count"))
      }.bind(this));
   }
}

</script>